import React, { useEffect, useState } from "react";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import { Avatar, Tooltip } from "@mui/joy";

import { FaBars } from "react-icons/fa6";
import { FaBarsStaggered } from "react-icons/fa6";
import { useNavigate } from "react-router";
import verifiedBadge from "../../assets/badge/verfiedbadge.png";
import { jwtDecode } from "jwt-decode";
import axiosInstance from "../../axiosInstance";
import Notification from "../notification/Notification";
import { useDispatch } from "react-redux";
import { setProfile } from "../../redux/profileSlice";

const Header = ({ isCollasped, handleCollapse }) => {
  const dispatch = useDispatch();
  const [Data, setData] = useState("");
  const [Rating, setRating] = useState("");
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("partnerToken");
    navigate("/auth/login");
  };
  const token = localStorage.getItem("partnerToken");
  const decodedToken = jwtDecode(token);
  const id = decodedToken.id;
  useEffect(() => {
    axiosInstance
      .get(`/partner/get-partner/${id}`)
      .then((res) => {
        setData(res.data);
        dispatch(setProfile(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    axiosInstance
      .get(`/partner/get-rating/${id}`)
      .then((res) => {
        setRating(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);
  return (
    <div className="h-[70px] border-b w-full bg-white flex justify-between items-center px-5">
      <div className="hidden md:block">
        <p>
          Hello <strong>{Data.full_name}</strong>, welcome back!{" "}
          {Rating && (
            <>
              Your rating is{" "}
              <span className="font-bold bg-[#0b299dfc] text-white whitespace-nowrap px-2 rounded-full">
                {Rating.overall_average}
              </span>
            </>
          )}
        </p>
      </div>
      <div className="md:hidden">
        {isCollasped ? (
          <FaBars onClick={handleCollapse} />
        ) : (
          <FaBarsStaggered onClick={handleCollapse} />
        )}
      </div>
      <div className="flex justify-end items-center gap-x-5">
        <div>
          <Notification />
        </div>
        <div>
          <Dropdown>
            <MenuButton variant="soft">
              <div className="flex justify-center items-center gap-x-5">
                <div>
                  <p>
                    <Avatar size="sm">
                      <img
                        src={`https://api.nirapod.xyz/partner/${Data.company_logo}`}
                        alt=""
                      />
                    </Avatar>
                  </p>
                </div>
                <div className="hidden md:block">
                  <p className="font-bold">{Data.full_name}</p>
                  <div className="flex justify-start items-center gap-x-1">
                    <p className="text-xs">{Data.company_name}</p>
                    <Tooltip title="Verified Partner">
                      <img src={verifiedBadge} alt="" className="w-[15px]" />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </MenuButton>
            <Menu>
              <MenuItem>Profile</MenuItem>
              <MenuItem>My account</MenuItem>
              <MenuItem>
                <button onClick={handleLogout}>Logout</button>
              </MenuItem>
            </Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
