import React, { useState } from "react";
import Table from "./Table";

const TabTable = ({ Data, handleViewData }) => {
  const [Tab, setTab] = useState(1);
  const [Active, setActive] = useState(1);
  const handleTab = (index) => {
    setTab(index);
    setActive(index);
  };
  const OngoingOrder = Data.filter((item) => item.status === "On Going");
  const CompleteOrder = Data.filter((item) => item.status === "Completed");
  const CancelledOrder = Data.filter((item) => item.status === "Cancelled");
  return (
    <div className="py-10">
      <div className="overflow-x-auto">
        <div className="flex justify-start items-center gap-x-4">
          <button
            onClick={() => handleTab(1)}
            className={`px-4  py-2 bg-[#ebebeb] duration-100 hover:bg-[#c7c7c7] text-[#5f5e5e] rounded-lg whitespace-nowrap ${
              Active === 1 ? "bg-[#c7c7c7]" : ""
            }`}
          >
            Ongoing{" "}
            {OngoingOrder.length > 0 && (
              <span className="bg-[#0b299dfc] text-white whitespace-nowrap px-2 font-semibold rounded-full">
                {OngoingOrder.length}
              </span>
            )}
          </button>
          <button
            onClick={() => handleTab(2)}
            className={`px-4 py-2 bg-[#ebebeb] duration-100 hover:bg-[#c7c7c7] text-[#5f5e5e] rounded-lg whitespace-nowrap ${
              Active === 2 ? "bg-[#c7c7c7]" : ""
            }`}
          >
            Complete{" "}
            {CompleteOrder.length > 0 && (
              <span className="bg-[#1a865dfc] text-white whitespace-nowrap px-2 font-semibold rounded-full">
                {CompleteOrder.length}
              </span>
            )}
          </button>
          <button
            onClick={() => handleTab(3)}
            className={`px-4 py-2 bg-[#ebebeb] duration-100 hover:bg-[#c7c7c7] text-[#5f5e5e] rounded-lg whitespace-nowrap ${
              Active === 3 ? "bg-[#c7c7c7]" : ""
            }`}
          >
            Cancel{" "}
            {CancelledOrder.length > 0 && (
              <span className="bg-[#b12038fc] text-white whitespace-nowrap px-2 font-semibold rounded-full">
                {CancelledOrder.length}
              </span>
            )}
          </button>
        </div>
      </div>
      {Tab === 1 && (
        <div className="py-4">
          <Table handleViewData={handleViewData} Data={OngoingOrder} />
        </div>
      )}
      {Tab === 2 && (
        <div className="py-4">
          <Table handleViewData={handleViewData} Data={CompleteOrder} />
        </div>
      )}
      {Tab === 3 && (
        <div className="py-4">
          <Table handleViewData={handleViewData} Data={CancelledOrder} />
        </div>
      )}
    </div>
  );
};

export default TabTable;
