import React, { useState } from "react";
import OrderList from "./OrderList";
import axiosInstance from "../../axiosInstance";
import { RxCross2 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import { FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";

const Table = ({ Data, handleViewData }) => {
  const profileData = useSelector((state) => state.profile.profile);
  const [collapsedIndex, setCollapsedIndex] = useState(null);
  const [Details, setDetails] = useState("");
  const handleViewDetails = (id, index) => {
    axiosInstance.get(`/order/orders/${id}`).then((res) => {
      setDetails(res.data);
      setCollapsedIndex((prevIndex) => (prevIndex === index ? null : index));
    });
  };
  const handleStatusChange = (id) => {
    axiosInstance
      .put(`/order/order-status/${id}`, {
        status: "Completed",
      })
      .then((res) => {
        if (res.status === 200) {
          handleViewData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleStatusCancelled = (id) => {
    axiosInstance
      .put(`/order/order-status/${id}`, {
        status: "Cancelled",
      })
      .then((res) => {
        if (res.status === 200) {
          handleViewData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(profileData);
  return (
    <div className="w-full h-auto ">
      <div className="overflow-x-auto shadow-md">
        <table className="w-full h-auto rounded-xl overflow-hidden">
          <thead>
            <tr className="w-full h-12 bg-[#ebebeb]">
              <th className="w-1/12 text-center">Customer</th>
              <th className="w-1/12 text-center">Order</th>
              <th className="w-1/12 text-center">Amount</th>
              <th className="w-1/12 text-center">Delivery</th>
              <th className="w-1/12 text-center">Status</th>
              <th className="w-1/12 text-center">Associated Partner</th>
              <th className="w-1/12 text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {Data.length > 0 ? (
              <>
                {Data &&
                  Data.map((item, index) => {
                    const orderCreated = new Date(
                      item.created_date
                    ).toLocaleString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    });
                    const deliveryDate = new Date(item.date).toLocaleDateString(
                      "en-US",
                      {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      }
                    );

                    return (
                      <React.Fragment key={item.id}>
                        <tr
                          className={`w-full h-12 border-b cursor-pointer  ${
                            index % 2 === 0 ? "bg-white" : "bg-gray-100"
                          }`}
                          onClick={() => handleViewDetails(item.id, index)}
                        >
                          <td className="w-2/12 text-center py-5 px-2">
                            <div className="flex flex-col gap-y-3 justify-start">
                              <p>
                                <strong>Name:</strong> {item.first_name}{" "}
                                {item.last_name}
                              </p>
                              <p>
                                <strong>Phone Number:</strong>{" "}
                                {item.phone_number}
                              </p>
                              <p>
                                <strong>Address:</strong> {item.house_no},{" "}
                                {item.road}, {item.city}
                              </p>
                            </div>
                          </td>
                          <td className="w-1/12 text-center">
                            <p>
                              <strong>Invoice No:</strong> {item.invoice_no}
                            </p>
                            <p>
                              <strong>Date:</strong> {orderCreated}
                            </p>
                          </td>
                          <td className="w-1/12 text-center">
                            {item.subtotal} BDT
                          </td>
                          <td className="w-1/12 text-center">
                            <p>
                              <strong>Date:</strong> {deliveryDate}
                            </p>
                            <p>
                              <strong>Time:</strong> {item.time}
                            </p>
                          </td>
                          <td className="w-1/12 text-center">
                            <p>
                              <strong>Order Status:</strong> {item.status}
                            </p>
                            <p>
                              <strong>Payment Status:</strong>{" "}
                              {item.payment_option}
                            </p>
                          </td>
                          <td className="w-1/12 text-center">
                            {item.assigned_associated_partner ??
                              "No Partner Assigned"}
                          </td>
                          <td className="w-1/12 text-center">
                            {item.status === "On Going" && (
                              <div className="flex gap-x-2 justify-center items-center">
                                <button
                                  onClick={() => handleStatusChange(item.id)}
                                  className="px-1 py-1 bg-green-500 rounded-lg"
                                >
                                  <TiTick className="text-2xl text-white" />
                                </button>
                                <button
                                  onClick={() => handleStatusCancelled(item.id)}
                                  className="px-1 py-1 bg-red-500 rounded-lg"
                                >
                                  <RxCross2 className="text-2xl text-white" />
                                </button>
                                <button className="px-1 py-1 bg-blue-500 rounded-lg">
                                  <FaUser className="text-2xl text-white" />
                                </button>
                              </div>
                            )}
                            {item.status === "Completed" && (
                              <div className="flex gap-x-2 justify-center items-center">
                                <button
                                  disabled
                                  className="px-1 py-1 bg-green-500/50 rounded-lg"
                                >
                                  <TiTick className="text-2xl text-white" />
                                </button>
                                <button
                                  disabled
                                  className="px-1 py-1 bg-red-500/50 rounded-lg"
                                >
                                  <RxCross2 className="text-2xl text-white" />
                                </button>
                                <button
                                  disabled
                                  className="px-1 py-1 bg-blue-500/50 rounded-lg"
                                >
                                  <FaUser className="text-2xl text-white" />
                                </button>
                              </div>
                            )}
                            {item.status === "Cancelled" && (
                              <div className="flex gap-x-2 justify-center items-center">
                                <button
                                  disabled
                                  className="px-1 py-1 bg-green-500/50 rounded-lg"
                                >
                                  <TiTick className="text-2xl text-white" />
                                </button>
                                <button
                                  disabled
                                  className="px-1 py-1 bg-red-500/50 rounded-lg"
                                >
                                  <RxCross2 className="text-2xl text-white" />
                                </button>
                                <button
                                  disabled
                                  className="px-1 py-1 bg-blue-500/50 rounded-lg"
                                >
                                  <FaUser className="text-2xl text-white" />
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                        {collapsedIndex === index && (
                          <>
                            <tr>
                              <td colSpan={7}>
                                <div className="text-center pt-2 font-bold text-xl">
                                  You will get{" "}
                                  {item.subtotal -
                                    (item.subtotal * profileData.commission) /
                                      100}{" "}
                                  BDT after commission {profileData.commission}%
                                </div>
                              </td>
                            </tr>
                            <tr className="w-full h-auto">
                              <td colSpan="7" className="">
                                <OrderList Details={Details} />
                              </td>
                            </tr>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
              </>
            ) : (
              <tr>
                <td colSpan="7" className="text-center font-bold py-5">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
