import React, { useEffect, useState } from "react";
import ModalClose from "@mui/joy/ModalClose";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Drawer from "@mui/joy/Drawer";
import DialogTitle from "@mui/joy/DialogTitle";
import { Badge } from "@mui/joy";
import { IoMdNotificationsOutline } from "react-icons/io";
import axiosInstance from "../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { setRequest } from "../../redux/notificationSlice";
import { jwtDecode } from "jwt-decode";
import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";

const Notification = () => {
  const dispatch = useDispatch();
  const requestData = useSelector((state) => state.notification.request);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("partnerToken");
  const decodedToken = jwtDecode(token);
  const id = decodedToken.id;
  const handleOrderRequest = () => {
    axiosInstance
      .get(`/order/order-requested/${id}`)
      .then((res) => {
        dispatch(setRequest(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleOrderRequest();
  }, []);
  const handleActionAccept = (order_id, action) => {
    axiosInstance
      .post(`/order/order-action`, {
        order_id,
        partner_id: id,
        action,
      })
      .then((res) => {
        if (res.status === 200) {
          handleOrderRequest();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleActionReject = (order_id, action) => {
    axiosInstance
      .post(`/order/order-action`, {
        order_id,
        partner_id: id,
        action,
      })
      .then((res) => {
        if (res.status === 200) {
          handleOrderRequest();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box sx={{ display: "flex" }}>
      <div onClick={() => setOpen(true)} className="cursor-pointer">
        {requestData && requestData.length > 0 ? (
          <Badge color="danger">
            <IoMdNotificationsOutline className="text-2xl" />
          </Badge>
        ) : (
          <IoMdNotificationsOutline className="text-2xl" />
        )}
      </div>
      <Drawer
        anchor="right"
        size="sm"
        open={open}
        onClose={() => setOpen(false)}
      >
        <ModalClose />
        <DialogTitle>All Notifications</DialogTitle>
        <div className="px-2 mt-2">
          {requestData.length > 0 ? (
            <>
              {requestData &&
                requestData.map((item, index) => {
                  const formattedDate = new Date(
                    item.order_created_date
                  ).toLocaleString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  });
                  const orderDate = new Date(
                    item.order_date
                  ).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  });
                  return (
                    <div
                      key={index}
                      className="py-3 bg-[#d7e7ff] duration-300 cursor-pointer hover:bg-[#f0f6ff] px-2 rounded-xl drop-shadow-md mt-3"
                    >
                      <div>
                        <div className="grid grid-cols-2">
                          <div>
                            <p>
                              <strong>Invoice No:</strong> {item.invoice_no}
                            </p>
                            <p>
                              <strong>Amount:</strong> {item.subtotal}
                            </p>
                            <p>
                              <strong>Location:</strong> {item.house_no},{" "}
                              {item.road}, {item.city}
                            </p>
                          </div>
                          <div>
                            <p className="font-bold">{formattedDate}</p>
                            <div className="">
                              <p>
                                <strong>Delivery Date:</strong> {orderDate}
                              </p>
                              <p>
                                <strong>Delivery Time:</strong>{" "}
                                {item.order_time}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-center items-center gap-x-2 pt-3">
                          <button
                            onClick={() =>
                              handleActionAccept(item.order_id, "accept")
                            }
                            className="bg-[#24ca7d] text-white px-4 py-1 rounded-lg whitespace-nowrap"
                          >
                            <TiTick />
                          </button>
                          <button
                            onClick={() =>
                              handleActionReject(item.order_id, "reject")
                            }
                            className="bg-[#ff2626] text-white px-4 py-1 rounded-lg whitespace-nowrap"
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </>
          ) : (
            <div className="text-center">
              <h1 className="text-2xl font-bold">No Notifications</h1>
            </div>
          )}
        </div>
      </Drawer>
    </Box>
  );
};

export default Notification;
