import { MdOutlineSpaceDashboard } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { FaShopware } from "react-icons/fa";

const SidebarMenu = [
  {
    name: "Dashboard",
    icon: <MdOutlineSpaceDashboard />,
    path: "/",
  },
  {
    name: "Recharge List",
    icon: <FaUser />,
    path: "recharge-list",
  },
  {
    name: "Orders",
    icon: <FaShopware />,
    path: "/orders",
  },
];

export default SidebarMenu;
