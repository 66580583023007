import Auth from "../layout/Auth";
import Login from "../view/auth/Login";

const auth = {
  path: "/auth",
  element: <Auth />,
  children: [
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "register",
      element: <Login />,
    },
  ],
};

export default auth;
