import { configureStore } from "@reduxjs/toolkit";
import notificationReducer from "./notificationSlice";
import profileReducer from "./profileSlice";
const store = configureStore({
  reducer: {
    notification: notificationReducer,
    profile: profileReducer,
  },
});

export default store;
