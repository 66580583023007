import React, { useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Header from "./../components/header/Header";
import { Outlet } from "react-router";
import Container from "../utils/Container";

const Layout = () => {
  const [isCollasped, setIsCollasped] = useState(false);
  const handleCollapse = () => {
    setIsCollasped(!isCollasped);
  };
  return (
    <div className="flex w-screen h-screen">
      <div className="fixed bg-white md:static z-10 duration-300 ease-in-out">
        <Sidebar handleCollapse={handleCollapse} isCollasped={isCollasped} />
      </div>
      <div className="flex flex-col w-full overflow-y-auto">
        <div className="sticky top-0 z-10">
          <Header isCollasped={isCollasped} handleCollapse={handleCollapse} />
        </div>
        <div className="">
          <Container>
            <Outlet />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Layout;
