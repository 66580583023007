import React, { useState } from "react";
import LoginBanner from "../../assets/banner/login.jpg";
import { FaPhone } from "react-icons/fa6";
import { TbPasswordUser } from "react-icons/tb";
import { FaRegEye } from "react-icons/fa";
import { TbPassword } from "react-icons/tb";
import axiosInstance from "../../axiosInstance";
import { useNavigate } from "react-router";

const Login = () => {
  const navigate = useNavigate();
  const [FormData, setFormData] = useState({
    phone_number: "",
    password: "",
  });
  const [ShowPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!ShowPassword);
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...FormData, [id]: value });
  };
  const handleLogin = () => {
    axiosInstance
      .post("/partner/login", FormData)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("partnerToken", res.data.token);
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="px-2 md:px-0 w-full h-full md:w-[850px] md:h-[550px] border-none md:border rounded-xl drop-shadow-md bg-white overflow-hidden">
      <div className="grid grid-cols-1 md:grid-cols-2 align-middle">
        <div className="w-full h-full hidden md:block">
          <img
            src={LoginBanner}
            alt=""
            className="w-full h-full object-contain"
          />
        </div>
        <div className="my-52 md:my-0">
          <div className=" md:py-28 px-4">
            <div className="flex flex-col gap-y-10">
              <div>
                <img
                  src="http://api.nirapod.xyz/assets/logo/nirapod1.png"
                  className="w-full h-[30px] object-contain"
                  alt=""
                />
              </div>
              <div className="flex justify-center items-center gap-x-2 border px-2 rounded-xl">
                <div className="flex justify-center items-center px-2">
                  <FaPhone className="text-xl text-[#c7c6c6]" />
                </div>
                <input
                  type="number"
                  name=""
                  id="phone_number"
                  className="w-full py-2  focus:outline-none"
                  placeholder="Enter Your Phone Number"
                  onChange={handleChange}
                />
              </div>
              <div className="flex justify-center items-center gap-x-2 border px-2 rounded-xl">
                <div className="flex justify-center items-center px-2">
                  <TbPasswordUser className="text-xl text-[#c7c6c6]" />
                </div>
                <input
                  type={ShowPassword ? "text" : "password"}
                  className="w-full py-2  focus:outline-none"
                  placeholder="Enter Your Password"
                  id="password"
                  onChange={handleChange}
                />
                <div className="flex justify-center items-center px-2">
                  {ShowPassword ? (
                    <button onClick={handleShowPassword}>
                      <TbPassword className="text-xl text-[#c7c6c6] cursor-pointer" />
                    </button>
                  ) : (
                    <button onClick={handleShowPassword} s>
                      <FaRegEye className="text-xl text-[#c7c6c6] cursor-pointer" />
                    </button>
                  )}
                </div>
              </div>
              {/* <div>
                <p className="text-center text-red-500 text-xs">
                  Please Enter Correct Phone Number or Password.
                </p>
              </div> */}
              <div>
                <button
                  onClick={handleLogin}
                  class="bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] text-white text-center font-semibold py-2 w-full rounded-full"
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
