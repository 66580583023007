import React from "react";

const OrderList = ({ Details }) => {
  return (
    <div className="mt-4 w-full border h-auto px-4 py-2 bg-white flex justify-between rounded-lg ">
      <table className="w-full">
        <thead>
          <tr className="text-center text-sm">
            <th className="border px-4 py-2">Sl No</th>
            <th className="border px-4 py-2">Service Name</th>
            <th className="border px-4 py-2">Price</th>
            <th className="border px-4 py-2">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {Details.services &&
            Details.services.map((item, index) => (
              <tr className="text-center text-sm" key={index}>
                <td className="border px-4 py-2">{index + 1}</td>
                <td className="border px-4 py-2">{item.service_name}</td>
                <td className="border px-4 py-2">৳{item.price}</td>
                <td className="border px-4 py-2">{item.quantity}</td>
              </tr>
            ))}
          <tr className=" text-sm ">
            <td colSpan="2" className="text-end font-semibold border px-4 py-2">
              Grand Total
            </td>
            <td
              colSpan=""
              className="text-center font-semibold border px-4 py-2"
            >
              ৳{Details.subtotal}
            </td>
            <td className="text-start border px-4 py-2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OrderList;
