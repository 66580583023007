import React, { useState } from "react";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { jwtDecode } from "jwt-decode";
import axiosInstance from "../../../axiosInstance";
const Recharge = () => {
  const [open, setOpen] = useState(false);
  const [Data, setData] = useState({
    amount: "",
    transaction_id: "",
    payment_method: "",
  });
  const handleChange = (e) => {
    const { id, value } = e.target;
    setData((prev) => ({ ...prev, [id]: value }));
  };
  const handleSelectPayment = (e) => {
    setData({ ...Data, payment_method: e.target.value });
  };
  const token = localStorage.getItem("partnerToken");
  const decodedToken = jwtDecode(token);
  const id = decodedToken.id;
  const handleRecharge = () => {
    axiosInstance
      .post("/recharge/add-recharge", {
        amount: Data.amount,
        transaction_id: Data.transaction_id,
        payment_method: Data.payment_method,
        partner_id: id,
      })
      .then((res) => {
        if (res.status === 201) {
          setOpen(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className="px-3 py-1 bg-green-500 text-white rounded-md"
      >
        Recharge Now
      </button>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{ maxWidth: 500, borderRadius: "md", p: 3, boxShadow: "lg" }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            sx={{ fontWeight: "lg", mb: 1 }}
          >
            Please Recharge Your Account
          </Typography>
          <div className="w-[400px]">
            <div className="py-2">
              <label htmlFor="">Payment Method</label>
              <select
                name=""
                id=""
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none"
                onChange={handleSelectPayment}
              >
                <option value="">Select Payment Method</option>
                <option value="Bkash">Bkash</option>
                <option value="Nagad">Nagad</option>
                <option value="Upay">Upay</option>
                <option value="Rocket">Rocket</option>
                <option value="Bank">Bank Transfer</option>
              </select>
            </div>
            <div className="py-2">
              <label htmlFor="">Amount</label>
              <input
                type="text"
                placeholder="Enter Amount"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none"
                onChange={handleChange}
                id="amount"
              />
            </div>
            <div className="py-2">
              <label htmlFor="">Transaction ID</label>
              <input
                type="text"
                placeholder="Enter Transaction ID"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none"
                onChange={handleChange}
                id="transaction_id"
              />
            </div>
            {Data.payment_method === "Bank" && (
              <div className="py-2">
                <div className="flex flex-col gap-y-2">
                  <p>
                    <strong>Bank Name:</strong> Eastern Bank Limited
                  </p>
                  <p>
                    <strong>A/C:</strong> 351561561861561
                  </p>
                  <p>
                    <strong>Brand Name:</strong> Gulshan
                  </p>
                  <p>
                    <strong>Routing No:</strong> 15686
                  </p>
                  <p>
                    <strong>Method:</strong> NFS
                  </p>
                  <p className="text-xs text-center">
                    Please check before paying
                  </p>
                </div>
              </div>
            )}
            {Data.payment_method === "Bkash" && (
              <div className="py-2">
                <div className="flex flex-col gap-y-2">
                  <p>
                    <strong>Bkash Number:</strong> +8801554870023
                  </p>
                  <p>
                    <strong>Method:</strong> Send Money
                  </p>

                  <p className="text-sm text-center pt-3">
                    Please add 1.5% with your amount otherwise account will not
                    recharge.
                  </p>
                </div>
              </div>
            )}
            {Data.payment_method === "Nagad" && (
              <div className="py-2">
                <div className="flex flex-col gap-y-2">
                  <p>
                    <strong>Bkash Number:</strong> +8801554870023
                  </p>
                  <p>
                    <strong>Method:</strong> Send Money
                  </p>

                  <p className="text-sm text-center pt-3">
                    Please add 1.5% with your amount otherwise account will not
                    recharge.
                  </p>
                </div>
              </div>
            )}
            {Data.payment_method === "Upay" && (
              <div className="py-2">
                <div className="flex flex-col gap-y-2">
                  <p>
                    <strong>Bkash Number:</strong> +8801554870023
                  </p>
                  <p>
                    <strong>Method:</strong> Send Money
                  </p>

                  <p className="text-sm text-center pt-3">
                    Please add 1.5% with your amount otherwise account will not
                    recharge.
                  </p>
                </div>
              </div>
            )}
            {Data.payment_method === "Rocket" && (
              <div className="py-2">
                <div className="flex flex-col gap-y-2">
                  <p>
                    <strong>Bkash Number:</strong> +8801554870023
                  </p>
                  <p>
                    <strong>Method:</strong> Send Money
                  </p>

                  <p className="text-sm text-center pt-3">
                    Please add 1.5% with your amount otherwise account will not
                    recharge.
                  </p>
                </div>
              </div>
            )}
            <div className="py-2">
              <button
                onClick={handleRecharge}
                className="px-3 py-1 bg-green-500 text-white rounded-md"
              >
                Recharge Now
              </button>
            </div>
          </div>
        </Sheet>
      </Modal>
    </>
  );
};

export default Recharge;
