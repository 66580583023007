import React, { Suspense } from "react";
import Layout from "../layout/Layout";
import PrivateRouter from "./privateRouter";
import Order from "../view/order/Order";
import RechargeList from "../view/recharge/RechargeList";
const Dashboard = React.lazy(() => import("../view/dashboard/Dashboard"));

const main = {
  path: "/",
  element: <Layout />,
  children: [
    {
      path: "/",
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <PrivateRouter>
            <Dashboard />
          </PrivateRouter>
        </Suspense>
      ),
    },
    {
      path: "/recharge-list",
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <PrivateRouter>
            <RechargeList />
          </PrivateRouter>
        </Suspense>
      ),
    },
    {
      path: "/orders",
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <PrivateRouter>
            <Order />
          </PrivateRouter>
        </Suspense>
      ),
    },
  ],
};

export default main;
