import React from "react";
import SidebarMenu from "./menu";
import { Link, useLocation } from "react-router";

const Sidebar = ({ isCollasped }) => {
  const param = useLocation();
  const active = param.pathname;

  return (
    <div
      className={`lg:w-[200px] 2xl:w-[250px] h-[100vh] border-r ${
        isCollasped ? "hidden" : "duration-300 ease-in-out"
      }`}
    >
      <div className="py-5">
        <img
          src="http://api.nirapod.xyz/assets/logo/nirapod1.png"
          alt=""
          className="w-full h-[30px] object-contain"
        />
      </div>
      <div className="py-5 px-5">
        {SidebarMenu.map((item, index) => (
          <div key={index} className="py-2">
            <div>
              <Link to={item.path}>
                <div
                  className={`flex justify-start items-center hover:bg-gray-100 duration-150 gap-x-5 py-2 px-2 rounded-xl ${
                    active === item.path ? "text-blue-500" : "text-[#BDBDBD]"
                  }`}
                >
                  <div className="text-2xl">{item.icon}</div>
                  <div>
                    <p className="text-lg font-semibold">{item.name}</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
