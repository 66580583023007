import React, { useEffect, useState } from "react";
import TabTable from "./TabTable";
import { jwtDecode } from "jwt-decode";
import axiosInstance from "../../axiosInstance";

const Order = () => {
  const [Data, setData] = useState([]);
  const token = localStorage.getItem("partnerToken");
  const decodedToken = jwtDecode(token);
  const id = decodedToken.id;
  const handleViewData = () => {
    axiosInstance
      .get(`/order/assigned_orders/${id}`)
      .then((res) => {
        setData(res.data.orders);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleViewData();
  }, []);
  return (
    <div>
      <TabTable handleViewData={handleViewData} Data={Data} />
    </div>
  );
};

export default Order;
