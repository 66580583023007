import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import Recharge from "../dashboard/components/Recharge";

const RechargeList = () => {
  const [Data, setData] = useState([]);
  const token = localStorage.getItem("partnerToken");
  const decodedToken = jwtDecode(token);
  const id = decodedToken.id;
  const handleViewData = () => {
    axiosInstance
      .get(`/recharge/recharges/${id}`)
      .then((res) => {
        setData(res.data.recharges);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleViewData();
  }, []);
  return (
    <>
      <div className="pb-5">
        <div className="flex justify-between">
          <div></div>
          <div>
            <Recharge />
          </div>
        </div>
      </div>
      <div className="w-full h-auto ">
        <div className="overflow-x-auto">
          <table className="w-full h-auto rounded-xl overflow-hidden ">
            <thead>
              <tr className="w-full h-12 bg-[#ebebeb]">
                <th className="w-1/12 text-center">ID</th>
                <th className="w-1/12 text-center">Payment Method</th>
                <th className="w-1/12 text-center">Transaction ID</th>
                <th className="w-1/12 text-center">Amount</th>
                <th className="w-1/12 text-center">Status</th>
                <th className="w-1/12 text-center">Recharge Date</th>
              </tr>
            </thead>
            <tbody>
              {Data.length > 0 ? (
                <>
                  {Data.slice(0, 5).map((item, index) => {
                    const orderCreated = new Date(
                      item.created_date
                    ).toLocaleString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    });
                    return (
                      <tr key={index} className="w-full h-12 bg-[#fffafa]">
                        <td className="w-1/12 text-center">{index + 1}</td>
                        <td className="w-1/12 text-center">
                          {item.payment_method}
                        </td>
                        <td className="w-1/12 text-center">
                          {item.transaction_id}
                        </td>
                        <td className="w-1/12 text-center">{item.amount}</td>
                        <td className="w-1/12 text-center">
                          {item.status === 1 ? "Balance Added" : "Pending"}
                        </td>

                        <td className="w-1/12 text-center">{orderCreated}</td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                  <tr>
                    <td colSpan="7" className="text-center">
                      No Data Found
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default RechargeList;
