import React from "react";

const Container = ({ children }) => {
  return (
    <div className=" mx-auto px-2 lg:px-5 2xl:px-10 py-2 md:py-10">
      {children}
    </div>
  );
};

export default Container;
